<template>
	<div>
		<headerTop></headerTop>
		<!-- 主体 -->
		<div class="cart_content wrap">
			<div class="header">
				<div @click="$router.push('/')" class="fl">
					<img src="../../assets/image/funuo.png" alt />
					孚诺大药房
				</div>
				<div class="fr">
					<el-steps :active="state" align-center>
						<el-step title="我的购物车" description></el-step>
						<el-step title="核对订单信息" description></el-step>
						<el-step title="提交订单" description></el-step>
					</el-steps>
				</div>
			</div>
			<div class="content">
				<div class="topTitle">购物车商品</div>
				<!-- 	<div class="title">
						<div class="goodstitle">商品</div>
						<div class="goodsmoney">单价</div>
						<div class="goodssum">数量</div>
						<div class="moneySum">总价</div>
						<div class="goodsSet">操作</div>
					</div> -->
				<div v-for="(v,i) in list.shop_shopcart_list" :key="i" class="goodlist">
					<div class="shop-name">
						<div class="all" @click="all(v)" :class="{active:v.active}">
							<div class="dian" v-show="v.active"></div>
						</div>
						<div class="shop_name">{{v.shop_name}}</div>
						<div class="fr title">
							<span>单价</span>
							<span>数量</span>
							<span>总价</span>
							<span>操作</span>
						</div>
					</div>
					<div v-for="(i,index) in v.goods_list" class="goodinfor">
						<div v-if="i.stock_num>=i.num" class="all" :class="{active:i.active}" @click="add(i,v)">
							<div class="dian" v-show="i.active"></div>
						</div>
						<div class="goodstitle">
							<img class=" imgs" :src="i.goods_img" />
						</div>
						<div class="text">
							<p class="two">{{i.goods_name}}</p>
							<p class="kc" v-if="i.stock_num<i.num">库存不足</p>
						</div>
						<div class="frbox">
							<div class="goodsmoney" v-if="i.num<i.disc_man_num  || i.disc_man_num==undefined">￥{{i.price}}</div>
							<div class="goodsmoney" v-else>￥{{i.price *(i.discount/10)}}</div>
							<div class="goodssum">
								<el-input-number v-model="i.num" :min="1" @change="onchange(i)"></el-input-number>
							</div>
							<div class="moneySum" v-if="i.num<i.disc_man_num || i.disc_man_num==undefined">￥{{i.price*1000* i.num/1000}}</div>
							<div class="moneySum" v-else>￥{{i.price *(i.discount/10)*1000* i.num/1000}}</div>
							<div class="goodsSet">
								<el-button @click="open(i)" type="danger" icon="el-icon-delete" circle></el-button>
							</div>
						
						</div>
					</div>

				</div>
				<div class="bottom">
					<div @click="allRemove()" class="goodstitle">删除选中商品</div>
					<div class="goodsmoney"></div>
					<div class="goodssum">
						<span>总价</span>
						￥{{ totalPrice.toFixed(2)}}
					</div>
					<div class="goodsSet">
						<button @click="GoCartOrder()">去结算</button>
					</div>
				</div>
			</div>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
		<el-button type="text" @click="open"></el-button>
		<el-button type="text" @click="differentId"></el-button>
	</div>

</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import publicBottom from "@/components/public/public_bottom";

	import {
		Goods_cart_index
	} from "@/request/index"; // 购物车列表
	import {
		incCart
	} from "@/request/index"; // +1
	import {
		desCart
	} from "@/request/index"; // -1
	import {
		delCart
	} from "@/request/index"; //删除
	import {
		seeCart
	} from "@/request/index"; //查看购物车
	export default {
		components: {
			headerTop,
			publicBottom
		},
		data() {
			return {
				state: 1, // 步骤条
				shop_id: -1,
				list: [],
				allType: 0,
				type: [], //样式 选择 id  也是选中
				totalPrice: 0,
				cartList: {
					shop_id: -1,
					good_list: []
				}, // 购物车临时集合
				isactive: false,
			};
		},
		created() {
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
		},
		mounted() {
			this.start();
		},
		methods: {
			open(v) {
				this.$confirm('确认删除选中商品吗？', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.remove(v);
				}).catch(() => {})
			},
			GoCartOrder() {
			
				if (this.cartList.good_list.length == 0) {
					this.$message.error("请选择要结算的商品");
				} else {
					this.$router.push({
						name: "购物车确认订单",
						query: {
							infor: JSON.stringify(this.cartList)
						}
					});
				}

			},
			remove(e) {
				delCart({
					token: this.$user.token,
					CartId: e.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
						this.list = [];
						this.start();
					}
				});
			},
			allRemove() {
				if (this.cartList.good_list == "") {
					this.$message.error("当前购物车没有商品");
				} else {
					delCart({
						token: this.$user.token,
						CartId: this.cartList.good_list
					}).then(res => {
						if (res.code == 1) {
							this.$message({
								message: "删除成功",
								type: "success"
							});
							this.cartList.shop_id = -1;
							this.cartList.good_list = [];
							this.start();
						}
					});
				}
			},
			// 计算总价
			getTotalPrice() {
				this.totalPrice = 0;
				console.log(this.list);
				for (let i of this.list.shop_shopcart_list) {
					if (i.shop_id == this.cartList.shop_id) {
						for (let j of i.goods_list) {
							if (j.active == true) {
								if (j.num < j.disc_man_num||j.disc_man_num==undefined) {
									this.totalPrice += j.price * 1000 * j.num / 1000;
								} else {
									this.totalPrice += j.price * (j.discount / 10) * 1000 * j.num / 1000;
								}


							}

						}
					}
				}
			},
			onchange(v) {
				this.$axios.post("api/Goods_cart/customCart", {
					token: this.$token,
					id: [v.id],
					CartId: v.id,
					num: v.num
				}).then(res => {
					console.log(res);
				})
				this.getTotalPrice();
			},
			// 全部选择
			all(v) {
				if (this.cartList.shop_id == -1) {
					if (v.active == false) {
						v.active = true;
						this.cartList.shop_id = v.shop_id;
						for (let i of v.goods_list) {
							console.log(v.goods_list);
							if(i.stock_num>=i.num){
							   this.cartList.good_list.push(i.id);
							   	i.active = true;
							}
						}
						this.$forceUpdate();
					} else {
						v.active = false;
						this.cartList.shop_id = -1;
						for (let i of v.goods_list) {
							this.cartList.good_list = [];
							i.active = false;
						}
						this.$forceUpdate();
					}
					this.getTotalPrice();
				} else if (this.cartList.shop_id == v.shop_id) {
					if (v.active == false) {
						v.active = true;
						this.cartList.shop_id = v.shop_id;
						for (let i of v.goods_list) {
								if(i.stock_num>=i.num){
									this.cartList.good_list.push(i.id);
									i.active = true;
								}
						
						}
						this.$forceUpdate();
					} else {
						v.active = false;
						for (let i of v.goods_list) {
							this.cartList.good_list = [];
							i.active = false;
						}
						this.totalPrice = 0;
						this.cartList.shop_id = -1;
						this.$forceUpdate();
					}

				} else {
					this.differentId();
				}

				console.log(this.cartList);

			},
			//  选中
			add(i, v) {
				if (this.cartList.shop_id == -1) {
					this.cartList.shop_id = i.shop_id;
					this.cartList.good_list.push(i.id);
					i.active = true;
					if (this.cartList.good_list.length == v.goods_list.length) {
						v.active = true;
					}
					this.$forceUpdate();
				} else if (this.cartList.shop_id == i.shop_id) {
					if (this.cartList.good_list.indexOf(i.id) == -1) {
						this.cartList.good_list.push(i.id);
						i.active = true;
						if (this.cartList.good_list.length == v.goods_list.length) {
							v.active = true;
						}
						this.$forceUpdate();
					} else {
						this.cartList.good_list.splice(this.cartList.good_list.indexOf(i.id), 1);
						i.active = false;
						if (this.cartList.good_list.length != v.goods_list.length) {
							v.active = false;
						}
						if (this.cartList.good_list.length == 0) {
							this.cartList.shop_id = -1;
							v.active = false;
						}
						this.$forceUpdate();
					}

				} else {
					this.differentId();
				}
				this.getTotalPrice();
			},
			differentId() {
				this.$alert('购物车内的商品不属于同一门店无法一同提交订单，请按门店分别提交', '提示', {
					confirmButtonText: '确定',
					callback: action => {}
				});
			},
			start() {
				Goods_cart_index({
					token: this.$user.token,
				}).then(res => {
					if (res.data != "") {
						this.list = res.data;
						for (let i of this.list.shop_shopcart_list) {
							i.active = false;
							for (let j of i.goods_list) {
								j.active = false;
							}
						}
					}
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	@import url("../../assets/less/index");

	.goodlist {
		width: 100%;
		border: 1px solid #38eec1;
		margin-top: 10px;
		border-radius: 10px;

		.all {
			width: 20px;
			height: 20px;
			background-color: #fff;
			border: 1px solid #ccc;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;
			border-radius: 100%;
			transition: all 0.2s linear;

			.dian {
				width: 12px;
				height: 12px;
				background-color: #555555;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 100%;
			}
		}

		.active {
			border: 1px solid #ccc !important;
		}

		.shop-name {
			width: 100%;
			height: 50px;
			line-height: 50px;
			background-color: #38eec1;
			padding-left: 50px;
			border-radius: 10px 10px 0 0;
			color: #fff;
			font-weight: 700;
			position: relative;

			.title {
				color: #000;
				width: 750px;
				height: 100%;
				display: flex;
				justify-content: space-around;
				position: absolute;
				background-color: #38eec1;
				border: none;
				border-radius: 0 10px 0 0;
				color: #fff;
				top: 0;
				right: 0;
			}
		}

		.goodinfor {
			display: flex;
			justify-content: space-between;
			padding: 10px;
			height: 120px;
			border-radius: 10px;
			transition: all 0.2s linear;
			box-shadow: 4px 1px 9px 0px #ccc;
			margin: 10px;
			position: relative;

			.frbox {
				width: 660px;
				display: flex;
				justify-content: space-around;
			}

			.all {
				width: 20px;
				height: 20px;
				background-color: #fff;
				border: 1px solid #ccc;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 10px;
				border-radius: 100%;
			}

			.goodstitle {
				flex: 2;

				img {
					width: 100px;
					height: 100px;
					margin-left: 30px;
				}
			}

			.text {
				flex: 5;
				margin-left: 20px;
				// display: flex;
				// justify-content: space-between;
				.kc{
					margin-top: 42px;
					color:red;
				}
				
			}

			.goodsmoney {
				flex: 2;
				color: red;
			}

			.goodssum {
				flex: 3.8;
			}

			.moneySum {
				flex: 2;
				color: red;
			}

			.goodsSet {
				flex: 2;
				color: red;
				text-align: center;
				margin-right: 5px;
			}
		}

		.goodinfor:hover {
			background-color: #F0F0F0;
		}
	}
</style>
